export const environment = {
  production: false,
  version: '1.0.0-VERSION_WILL_BE_REPLACED_BY_CICD',
  auth: {
    cognitoClientId: '6kldfspck86pshikn7358gghfq',
    cognitoUserPoolId: 'ap-southeast-2_gtuqqgIIq',
    cognitoAwsRegion: 'ap-southeast-2',
    cognitoDomain: 'uoa-pool',
    redirectUri: 'https://email-signature.sandbox.amazon.auckland.ac.nz/',
    logoutUri: 'https://email-signature.sandbox.amazon.auckland.ac.nz/',
    scopes: 'openid https://apis.auckland.ac.nz/employee-read https://apis.auckland.ac.nz/identity-read',
    codeChallengeMethod: 'S256',
  },
  privateUrlKeyWords: {
    // default optional to false
    whoNeedBearerToken: [{ url: 'apis.test.auckland.ac.nz', optional: false }],
  },
};

export const properties = {
  employmentAPIUrl: 'https://apis.test.auckland.ac.nz/employment/v1',
  identityAPIUrl: 'https://apis.test.auckland.ac.nz/identity/v3',
  employmentAPIKey: '',
  identityAPIKey: '',
  upi:'self'
}