import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AuthModule, CognitoConfigService, StorageService } from '@uoa/auth';
import {ErrorPagesModule, UoaErrorsConfig} from '@uoa/error-pages';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppAuthConfigService, AppStorageService } from './auth/services';
import {ErrorConfig} from './error-routing/ErrorConfig';

@NgModule({
  declarations: [AppComponent],

  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, AuthModule, ErrorPagesModule, IonicStorageModule.forRoot()],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: CognitoConfigService, useClass: AppAuthConfigService },
    { provide: StorageService, useClass: AppStorageService },
    { provide: UoaErrorsConfig, useClass: ErrorConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
